import React from 'react'
import styled from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'

import {
  Layout,
  Seo,
  HeaderInternal,
  Footer,
  LeadCapture,
} from 'components'

export default function PrivacyPolice () {
  return (
    <Layout>
      <Seo />
      <HeaderInternal
        title='Política de privacidade'
      />
      <Main>
        <h1>TERMOS DE USO - POLÍTICA DE PRIVACIDADE DA PLATAFORMA DO PROFESSOR FERRETTO </h1>

        <p>
          Esta Política de Privacidade tem como objetivo informar a você, Usuário do Site da Plataforma do Professor Ferretto <a href={process.env.GATSBY_SITE_URL}>www.professorferretto.com.br</a> (“Plataforma do Professor Ferretto”), desenvolvida e provida por, FERRETTO CURSOS ON LINE LTDA, pessoa jurídica de direito privado, inscrita no CNPJ sob o nº 40.424.646/0001-10, com sede na Rua Uruguai, nº 421, Sala 802, Centro, Passo Fundo/RS, CEP: 99.010-110 (“Plataforma do Professor Ferretto”), acerca de quais informações são coletadas quando da sua utilização da Plataforma do Professor Ferretto, além de como elas são tratadas, em que situações são compartilhadas e quando são excluídas, conforme exigido pela Lei nº 13.709/2018 (Lei Geral de Proteção de Dados Pessoais – LGPD).
        </p>
        <p>
          A Plataforma do Professor Ferretto tem o compromisso com a transparência, a privacidade e a segurança dos dados de seus Usuários durante todo o processo de interação com nossa Plataforma do Professor Ferretto.  Os dados cadastrais dos Usuários não são divulgados para terceiros, exceto quando necessários para o processo de entrega, para cobrança, pela legislação vigente ou participação em promoções solicitadas pelos Usuários.
        </p>
        <p>
          A aceitação a esta Política de Privacidade se dará no ato do seu clique no botão “Aceito”, de modo que o Usuário concorda e permite o acesso às suas informações a partir de seu cadastro na Plataforma do Professor Ferretto, manifestando consentimento livre, expresso e informado.
        </p>
        <p>
          <b>
            O USUÁRIO, AO FORNECER SEUS DADOS PESSOAIS, DECLARA CONHECER E ACEITAR ESTA POLÍTICA DE PRIVACIDADE. CASO NÃO CONCORDE COM ALGUMA CLÁUSULA DESTA POLÍTICA OU DOS TERMOS DE USO, NÃO DEVERÁ UTILIZAR QUAISQUER DE NOSSOS SERVIÇOS.
          </b>
        </p>
        <p>
          Esta Política de Privacidade deve ser lida em conjunto com o nosso  <Link to='/politica-de-privacidade#termos-de-uso'>Termos de uso</Link>. Caso tenha dúvidas ou precise tratar de qualquer assunto relacionado a esta Política de Privacidade, entre em contato conosco através do e-mail: <a href='mailto:privacidade@professorferretto.com.br'>privacidade@professorferretto.com.br</a>
        </p>

        <h2>1. A QUEM ESSA POLÍTICA DE PRIVACIDADE SE APLICA? </h2>

        <p>
          1.1. Aplica-se a todos os Usuários da Plataforma do Professor Ferretto, que de alguma forma tenham dados pessoais tratados por nós.
        </p>

        <h2>2. QUE TIPO DE INFORMAÇÕES PESSOAIS COLETAMOS E UTILIZAMOS? </h2>

        <p>
          2.1. A Plataforma do Professor Ferretto coleta e armazena os seguintes tipos de informações:

          <li><b><u>Quando é realizado o cadastro</u></b> junto à Plataforma do Professor Ferretto, demonstrando interesse na contratação dos serviços.</li>

          <li><b><u>Quando o Usuário efetua a compra de algum serviço e/ou produto:</u></b> da Plataforma do Professor Ferretto é necessário a coleta de dados pessoais para a emissão da nota fiscal.</li>

          <li><b><u>Informações que coletamos diretamente ou indiretamente do Usuário:</u></b> dentre as informações que podem ser solicitadas estão Nome completo, CPF, e-mail e telefone.</li>

          <li><b><u>Informações coletadas automaticamente pela Plataforma do Professor Ferretto:</u></b> coletamos e armazenamos determinadas informações sempre que um Usuário interage conosco em algum canal digital. Por exemplo, utilizamos cookies e obtemos algumas informações de navegação quando você acessa o nosso Site; quando você clica em anúncios, e-mails de parceiros, informações de uso, log e dispositivo, informações por tecnologias de rastreamento e outros conteúdos fornecidos por nós em outros sites.</li>

          <li><b><u>Informações coletadas por meio de Tecnologia:</u></b> coletamos e armazenamos determinadas informações por meio de dispositivo, provedor de Internet, navegador, sistema operacional e resolução de tela.</li>

          <li><b><u>Informações de acesso:</u></b>  coletamos e armazenamos informações de páginas acessadas, tempo de sessão nas páginas, conversão dentro dos nossos domínios, número total de usuários que visitaram determinada página, número de novos usuários acessando determinada página e interesses gerais sobre os usuários que acessam o Site.</li>

          <li><b><u>Informações geográficas e a origem do tráfego:</u></b> coletamos e armazenamos informações sobre o idioma e a localização do Usuário. Também, coletamos informações a partir de onde os Usuários acessaram o Site: redes sociais, direto da barra do de endereço do navegador, do google, etc.</li>

          <li><b><u>Informações de dados financeiros:</u></b> informações sobre pagamento, dados bancários e informações financeiras, a depender do seu tipo de relacionamento.</li>
        </p>

        <p>2.2. O Usuário pode, a qualquer momento, bloquear algumas destas tecnologias para coleta automática de dados através do seu navegador. No entanto, caso essa configuração seja implementada, é possível que algumas das funções oferecidas pelo Site deixem de funcionar corretamente. </p>

        <p>2.3. O Usuário desde já se encontra ciente acerca das informações coletadas por meio do Site e expressa consentimento, livre, expresso e informado com relação à coleta de tais informações.</p>

        <h2>3. POR QUE COLETAMOS SEUS DADOS PESSOAIS? </h2>

        <p>3.1. A Plataforma do Professor Ferretto coleta os dados pessoais do Usuário com a finalidade comercial de negociação, dos produtos e serviços, e, atendimento ao Usuário. </p>

        <p id='p1'>3.1.1. A Plataforma do Professor Ferretto considera todas as informações coletados por meio do Site como confidenciais. Portanto, somente as utilizará da forma descrita e pelo Usuário autorizado. </p>

        <p>3.2. Todas as informações cadastradas e coletadas pela Plataforma do Professor Ferretto são utilizadas para a prestação de serviços ou venda dos produtos da Plataforma do Professor Ferretto, para melhorar a experiência do Usuário. </p>

        <p>3.3. Coletamos esses dados pessoais para atender, prestar, desenvolver e melhorar os produtos e serviços que oferecemos para as seguintes finalidades: </p>

        <p>
          <li>disponibilizar aos Usuários um ambiente eletrônico por meio do qual os Usuários podem obter mais informações sobre os produtos e serviços da Plataforma do Professor Ferretto; </li>

          <li>viabilizar a formalização da compra de um serviço adquirido pelo Site da Plataforma do Professor Ferretto; </li>

          <li>oferecer funcionalidades, analisar desempenhos, corrigir erros e melhorar a usabilidade e a efetividade dos serviços da Plataforma do Professor Ferretto; </li>

          <li>disponibilizar anúncio de um produto que o Usuário buscou em nosso Site; </li>

          <li>fornecer, operar, manter e melhorar os serviços da Plataforma do Professor Ferretto; </li>

          <li>enviar avisos técnicos, atualizações, alertas de segurança e mensagens administrativas e de suporte; </li>

          <li>fornecer e entregar os serviços e recursos solicitados, processar e concluir transações e enviar informações relacionadas, incluindo confirmações de compra e faturas; </li>

          <li>responder aos seus comentários, perguntas e solicitações e fornecer atendimento e suporte ao Usuário; </li>

          <li>analisar o comportamento do Usuário na Plataforma do Professor Ferretto; </li>

          <li>comunicar com você sobre serviços, recursos, pesquisas, boletins informativos, ofertas, promoções, eventos e fornecer outras notícias ou informações sobre a Plataforma do Professor Ferretto e nossos parceiros selecionados; </li>

          <li>monitorar e analisar tendências, uso e atividades relacionadas à Plataforma do Professor Ferretto, para fins de marketing ou publicidade; </li>

          <li>investigar e prevenir transações fraudulentas, acesso não autorizado ao Site e outras atividades ilegais; </li>

          <li>personalizar e melhorar o Serviço e fornecer conteúdo e/ou recursos que correspondam aos seus interesses e preferências ou de outra forma personalizar sua experiência junto à Plataforma do Professor Ferretto. </li>
        </p>
        <p>3.5. A Plataforma do Professor Ferretto não dividirá essas informações espontaneamente com o governo ou órgãos públicos, a não ser que seja obrigada a fazê-lo em virtude de lei ou regulamentação aplicável. </p>

        <h2>4. QUAIS SÃO OS DIREITOS DO TITULAR DE DADOS?</h2>

        <p>4.1. A Plataforma do Professor Ferretto terá 15 (quinze) dias para responder às solicitações dos titulares (Usuários). Os pedidos serão analisados conforme previsto em legislação vigente e, por questões legais, algumas solicitações podem não ser atendidas. </p>

        <p>4.2. Os titulares (Usuários) dos dados terão determinados direitos em relação aos seus dados pessoais. Tais direitos incluem, mas não se limitam a: </p>

        <p>
          <li>Receber informações claras e completas sobre o tratamento de seus dados pessoais, incluindo sobre as hipóteses de compartilhamento de dados pessoais; </li>

          <li>Solicitar o acesso a seus dados pessoais e/ou confirmação da existência de tratamento de dados; </li>

          <li>Solicitar que retifiquemos quaisquer dados pessoais imperiosos, incompletos e desatualizados; </li>

          <li>Se opor às atividades de tratamento, solicitar a anonimização e eliminação de dados pessoais, em circunstâncias específicas; </li>

          <li>Solicitar a portabilidade de seus dados pessoais; </li>

          <li>Revogar o consentimento a qualquer momento, quando a Plataforma do Professor Ferretto tratar seus dados com base no consentimento; e </li>

          <li>Solicitar a revisão de decisões automatizadas que possam afetar seus interesses. </li>
        </p>

        <h2>5. COMO EXERCER OS SEUS DIREITOS? </h2>

        <p>5.1. Os direitos mencionados acima serão garantidos e poderão ser exercidos pelos Usuários a qualquer tempo. Toda e qualquer solicitação, reclamação, pedido de informação ou exercício de direitos do Titular de Dados poderá ser realizada pelo e-mail: <a href='mailto:privacidade@professorferretto.com.br'>privacidade@professorferretto.com.br</a> </p>

        <h2>6. OS DADOS PESSOAIS SÃO TRATADOS DE ACORDO COM AS BASES LEGAIS PREVISTAS EM LEI, SENDO ELAS: </h2>

        <p>
          <li>Cumprimento das obrigações legais e regulatórias; </li>

          <li>Exercício regular de direitos; </li>

          <li>Execução dos contratos firmados com seus clientes; </li>

          <li>Interesses legítimos da Plataforma do Professor Ferretto, sem ferir os direitos e liberdades fundamentais, respeitando a legítima expectativa do nosso Usuário; </li>

          <li>Consentimento do nosso Usuário; </li>

          <li>Para garantir a prevenção à fraude e à segurança, nos processos de identificação e autenticação de cadastro em sistemas eletrônicos. </li>
        </p>

        <h2>7. POR QUANTO TEMPO GUARDAMOS OS DADOS PESSOAIS?</h2>

        <p>7.1. A Plataforma do Professor Ferretto encerra o tratamento de dados pessoais do Usuário assim que verificado o alcance da finalidade para o qual foi coletado ou quando os dados deixam de ser necessários ou pertinentes ao alcance da finalidade específica.</p>

        <p>7.2. Se houver alguma justificativa legal ou regulatória, os dados poderão continuar armazenados ainda que a finalidade para a qual foram coletados ou tenham sido tratados tenha se exaurido.</p>

        <p>7.3. Uma vez finalizado o tratamento, observadas as disposições desta seção, os dados são apagados ou anonimizados.</p>

        <p>7.4. A Plataforma do Professor Ferretto empreenderá os melhores esforços para atender a todos os pedidos de exclusão, no menor espaço de tempo possível. Tal exclusão, no entanto, removerá também o cadastro do Usuário do Site, que não conseguirá mais acessá-lo.</p>

        <p>7.5. A pedido do Usuário, a Plataforma do Professor Ferretto eliminará ou anonimizará os seus dados pessoais de modo que não o identifiquem, exceto se for legalmente permitido ou obrigatório manter determinados dados pessoais.</p>

        <h2>8. COM QUEM COMPARTILHAMOS SEUS DADOS? </h2>

        <p>8.1. <b><u>Prestadores de Serviços:</u></b> Podemos empregar empresas e indivíduos terceirizados para administrar e prestar serviços em nosso nome (como empresas que fornecem suporte ao cliente, empresas que contratamos para hospedar, gerenciar, manter e desenvolver nosso aplicativo, sistemas de TI). Esses terceiros podem usar suas informações apenas como indicado pela Plataforma do Professor Ferretto e de maneira consistente com esta Política de Privacidade, e são proibidos de usar ou divulgar suas informações para qualquer outra finalidade. Adicionalmente, dados como IP (Internet Protocol) e informações de acesso são enviadas para os serviços de servidor do Site. </p>

        <p>8.2. <b><u>Consultores Profissionais:</u></b> A Plataforma do Professor Ferretto poderá divulgar seus dados pessoais para consultores profissionais, como advogados, auditores e seguradoras, sempre que necessário, no decorrer dos serviços profissionais que eles prestem a nós. </p>

        <p>8.3. <b><u>Conformidade com as leis e o cumprimento das leis; proteção e segurança:</u></b> A Plataforma do Professor Ferretto poderá divulgar informações sobre o Usuário a autoridades governamentais ou policiais (incluindo autoridades fiscais) ou a entidades privadas, conforme exigido por lei, e divulgar e usar as informações que consideramos necessárias ou adequadas para (a) cumprir as leis aplicáveis, solicitações legais e processos legais, como responder a intimações ou solicitações de autoridades governamentais; (b) cumprir os termos e condições que regem nossos produtos e serviços; (c) proteger nossos direitos, privacidade, segurança ou propriedade, e/ou do Usuário ou de outros; e (d) proteger, investigar e impedir atividades fraudulentas, prejudiciais, não autorizadas, antiéticas ou ilegais. </p>

        <p>8.4. <b><u>Transferência de Negócios:</u></b> A Plataforma do Professor Ferretto poderá vender ou transferir alguns ou todos os seus negócios ou ativos, incluindo seus dados pessoais, em relação a uma transação comercial (ou transação comercial potencial), como uma fusão, consolidação, aquisição, reorganização ou venda de ativos ou no caso de falência, em cujo caso faremos esforços consideráveis para exigir que o destinatário honre esta Política de Privacidade. </p>

        <p>8.5. <b><u>Compartilhamento de Dados Pessoais Sensíveis.</u></b> A Plataforma do Professor Ferretto pode compartilhar informações que coleta, inclusive seus dados sensíveis, com parceiros comerciais. O compartilhamento das informações ocorrerá de forma anônima, sempre que for possível, visando preservar a privacidade do Usuário. Por meio deste documento, o Usuário autoriza expressamente tais compartilhamentos. </p>

        <p>8.6. <b><u>Compartilhamento com outras empresas:</u></b> A Plataforma do Professor Ferretto poderá compartilhar dados com empresas de Vale Alimentação, de Plano de Saúde, de Contabilidade, de Armazenamento de dados, de Manutenção da Plataforma, etc.  </p>

        <p>8.7. Outros compartilhamentos podem ser realizados com a finalidade de atender às suas solicitações e prestar devidamente os serviços contratados. Para as informações detalhadas sobre os nomes dos terceiros com os quais compartilhamos seus dados pessoais, entre em contato com nosso Encarregado através do e-mail: <a href='mailto:privacidade@professorferretto.com.br'>privacidade@professorferretto.com.br</a> </p>

        <h2>9. CRIANÇAS E ADOLESCENTES PODEM COMPRAR NA PLATAFORMA DO PROFESSOR FERRETTO? </h2>

        <p>9.1. A Plataforma do Professor Ferretto poderá coletar dados pessoais de crianças e adolescentes e garantirá que o tratamento sempre ocorra no melhor interesse da criança e do adolescente.</p>

        <p>
          <li><b><u>Crianças:</u></b> O tratamento de dados pessoais de crianças será realizado mediante consentimento específico e em destaque de pelo menos um de seus pais ou responsável legal. </li>

          <li><b><u>Adolescente:</u></b> Em relação ao adolescente, o tratamento de seus dados pessoais poderá ocorrer independentemente do consentimento de um dos pais ou responsável legal, desde que presente outro fundamento legal que autorize o tratamento, nos termos da LGPD. </li>
        </p>

        <h2>10. ONDE SÃO ARMAZENADAS AS INFORMAÇÕES DOS DADOS PESSOAIS E QUAIS SÃOS AS FINALIDADES? </h2>

        <p>10.1. Todos os dados pessoais dos Usuários coletados por nós são armazenados no POSTGRESQL, AMAZON AWS e DIGITAL OCEAN. </p>

        <p>10.2. A Plataforma do Professor Ferretto armazena e compartilha as informações com as referidas empresas para as seguintes finalidades: </p>

        <p>
          <li>POSTGRESQL: armazenamento de dados.</li>

          <li>AMAZON AWS: armazenamento de dados. </li>

          <li>DIGITAL OCEAN: armazenamento de dados.</li>
        </p>

        <h2>COOKIES E TECNOLOGIAS DE RASTREAMENTO.</h2>

        <p>11.1. Cookies são pequenos arquivos de dados que são colocados no seu computador ou em outros dispositivos (como 'smartphones' ou 'tablets') enquanto você navega no site.</p>

        <p>11.2. Utilizamos cookies, pixels e outras tecnologias (coletivamente, "cookies") para reconhecer seu navegador ou dispositivo, aprender mais sobre seus interesses, fornecer recursos e serviços essenciais, melhorar sua experiência em nosso site e para:</p>

        <p>
          <li>acompanhar suas preferências para enviar somente anúncios de seu interesse. Você pode definir suas preferências por meio de sua conta;</li>

          <li>acompanhar os itens armazenados das suas compras;</li>

          <li>realização de pesquisas e diagnósticos para melhorar o conteúdo e produtos;</li>

          <li>impedir atividades fraudulentas;</li>

          <li>melhorar a segurança.</li>
        </p>

        <p id='p1'>11.2.1. A Plataforma do Professor Ferretto utiliza cookies na navegação por meio de do Google e do Facebook.</p>

        <p>11.3 Lembramos que você pode, a qualquer momento, ativar em seu navegador mecanismos para informá-lo quando os cookies estiverem acionados ou, ainda, impedir que sejam ativados, através das preferências do seu navegador, mas com isso você pode não conseguir concluir o cadastro e/ou a compra.</p>

        <p>11.4. A Plataforma do Professor Ferretto também pode utilizar de tecnologias como beacons, pixel, tags e scripts para analisar tendências, administrar o Site, rastrear os movimentos dos Usuários no Site e coletar informações demográficas sobre nossa base de Usuários como um todo.</p>

        <p>11.5. A Plataforma do Professor Ferretto pode receber relatórios com base no uso dessas tecnologias por essas empresas de forma individual e agregada.</p>

        <h2>12. SEGURANÇA NO TRATAMENTO DOS DADOS PESSOAIS.</h2>

        <p>12.1. A Plataforma do Professor Ferretto observa todas as normas aplicáveis às medidas de segurança da informação pessoal e emprega os padrões mais avançados em matéria de proteção da confidencialidade destas informações. Ainda assim, é necessário considerar que a segurança perfeita não existe na Internet. Portanto, a Plataforma do Professor Ferretto não será responsável por interceptações ilegais ou violação de seus sistemas ou bases de dados por parte de pessoas não autorizadas e tampouco se responsabiliza pela indevida utilização da informação obtida por esses meios.</p>

        <p>12.2. Todos os dados pessoais informados ao nosso Site são armazenados em um banco de dados reservado e com acesso restrito a pessoas habilitadas, que são obrigadas, por contrato, a manter a confidencialidade das informações e não as utilizar inadequadamente.</p>

        <p>12.3. A Plataforma do Professor Ferretto utiliza o HTTPS, para garantir que os dados pessoais transmitidos entre o dispositivo do Usuário e o Site da Plataforma do Professor Ferretto sejam completamente criptografadas.</p>

        <h2>13. PROIBIÇÃO DE REVENDA.</h2>

        <p>13.1. Você concorda em não reproduzir, duplicar, copiar, vender, revender ou explorar com quaisquer fins comerciais, qualquer porção da Plataforma ProfessorFerretto.com.br, o uso da plataforma, ou o acesso à plataforma para o fim de vendas de bens ou serviços, ou a promoção de uma companhia, bem ou serviço.</p>

        <h2>INDENIZAÇÃO</h2>

        <p>14.1. Você concorda indenizar o Site Professor Ferretto, seus diretores, sócios, agentes, funcionários, consultores, afiliados, subsidiários e parceiros e mantê-los livres de qualquer declaração ou demanda, incluindo despesas de advogados feitas por terceiros devido ao ou derivado de um descumprimento seu destes Termos ou dos documentos que ele incorpora como referência, o seu uso da Plataforma, Suas Informações, sua violação de qualquer lei, estatuto, ordem ou regulamento ou os direitos de terceiros.</p>

        <h2>15. TRANSFERÊNCIA INTERNACIONAL DE DADOS.</h2>

        <p>15.1. A Plataforma do Professor Ferretto poderá armazenar seus dados em servidores de computação em nuvens localizados fora do Brasil, observando todos os requisitos estabelecidos pela lei 13.709/18 (Lei Geral de Proteção de Dados - LGPD) e sempre busca as melhores e mais modernas práticas de segurança da informação.</p>

        <p>15.2. Assim, ao concordar com esta Política de Privacidade, o Usuário concorda também que os dados pessoais podem estar sujeitos a transferências internacionais e se declara ciente de todas as garantias aplicáveis ao tratamento no exterior, conforme esta Política de Privacidade.</p>

        <h2>16. COMO ENTRAR EM CONTATO COM O ENCARREGADO DA PROTEÇÃO DE DADOS PESSOAIS?</h2>

        <p>16.1. O encarregado da proteção de dados é o responsável escolhido pela Plataforma do Professor Ferretto para atuar como canal de comunicação entre o controlador, os titulares dos dados e a Autoridade Nacional de Proteção de Dados (ANPD). Qualquer dúvida poderá ser encaminhada para:</p>

        <p>
          KATIANY ROSSI
          <br />
          E-mail: <a href='mailto:privacidade@professorferretto.com.br'>privacidade@professorferretto.com.br</a>
        </p>

        <h2>17. ALTERAÇÕES DESTA POLÍTICA DE PRIVACIDADE.</h2>

        <p>17.1. A presente versão desta Política de Privacidade foi atualizada pela última vez em 03 de novembro de 2022.</p>

        <p>17.2. A Plataforma do Professor Ferretto se reserva o direito de modificar, a qualquer momento esta Política de Privacidade, especialmente para adaptar às evoluções do Site, seja pela disponibilização de novas funcionalidades, seja pela supressão ou modificação daquelas já existentes ou em virtude de alterações na legislação. Alterações e esclarecimentos vão surtir efeito imediatamente após sua publicação no Site.</p>

        <p>17.3. Sempre que ocorrer qualquer modificação nestes Termos de Uso, a Plataforma do Professor Ferretto notificará no Site ao Usuário, que os Termos foram modificados. Devendo o Usuário concordar com eles para continuar acessando o Site.</p>

        <p>17.4. Ao utilizar o serviço após eventuais modificações, o Usuário demonstra sua concordância com as novas normas. Caso discorde de alguma das modificações, o Usuário deverá pedir, imediatamente, o cancelamento de sua conta e apresentar a sua ressalva ao serviço de atendimento, se assim o desejar.</p>

        <h2>18. DO DIREITO APLICÁVEL E DO FORO.</h2>

        <p>18.1. Essa Política de Privacidade é regida pelas leis da República Federativa do Brasil.</p>

        <p>18.2. Todas as controvérsias desta Política de Privacidade serão solucionadas pelo foro da Comarca de Passo Fundo, Estado do Rio Grande do Sul, Brasil, com exclusão de qualquer outro, por mais privilegiado que seja ou venha a ser.</p>

        <p>DADOS DE CONTATO

          <br />FERRETTO CURSOS ON LINE LTDA

          <br />CNPJ nº 40.424.646/0001-10

          <br />Rua Uruguai, nº 421, Sala 802

          <br />Centro, Passo Fundo/RS

          <br />CEP 99.010-110

          <br /><a href='mailto:privacidade@professorferretto.com.br'>privacidade@professorferretto.com.br</a>

          <br /><a href='mailto:contato@professorferretto.com.br'>contato@professorferretto.com.br</a>

          <br />Redes Sociais:

          <br /><a href='https://facebook.com/professorferretto'>Facebook</a>

          <br /><a href='https://www.instagram.com/professorferretto'>Instagram</a>

          <br />Chat online do Site

          <br />Horários de Atendimento

          <br />Segunda à Sexta. Das 08h às 12h e das 13h às 18h (menos feriados nacionais, estaduais e municipais.
        </p>

        <div id='termos' />
        <h1 id='termos-de-uso'>TERMOS E CONDIÇÕES DE USO</h1>
        <p>Data da última atualização 03/11/2022</p>
        <p>Oi, tudo bem? Bem-vindo(a) à Plataforma do Professor Ferretto!</p>

        <p>Ficamos felizes por você estar aqui e esperamos que você aproveite tudo o que temos para oferecer.</p>

        <p>A seguir apresentamos a você os Termos de Uso (“Termos” ou “Termos de Uso” ou “Instrumento”), documento que relaciona as principais regras a serem observadas por todos que acessam e utilizam as funcionalidades do Site  (www.professorferretto.com.br) do Professor Ferretto (“Plataforma”, “Plataforma do Professor Ferretto” ou “Site”).</p>

        <p>Leia estes Termos de Uso com atenção, pois são um contrato vinculativo entre você e a empresa da Plataforma do Professor Ferretto.</p>

        <p>Por este instrumento e por estarem de acordo com os termos que se seguem, tendo por Partes, de um lado, FERRETTO CURSOS ON LINE LTDA, pessoa jurídica de direito privado, inscrita no CNPJ sob o nº 40.424.646/0001-10, com sede na Rua Uruguai, nº 421, Sala 802, Centro, Passo Fundo/RS, CEP: 99.010-110, chamado de “Plataforma do Professor Ferretto”, e, de outro lado, qualquer pessoa que acessa, utiliza e/ou se cadastra na Plataforma (usuário, aluno ou visitante), chamado de “Usuário”.</p>

        <p>Esta Plataforma e todo o seu conteúdo é controlada e operada pela empresa da Plataforma do Professor Ferretto, sendo, portanto, todos os direitos reservados.</p>

        <p>Caso queira nos dar algum feedback sobre a Plataforma, tenha dúvidas ou precise tratar de qualquer assunto relacionado a estes Termos de Uso, entre em contato conosco pelo nosso e-mail: <a href='mailto:privacidade@professorferretto.com.br'>privacidade@professorferretto.com.br</a></p>

        <h2>1. OBJETO</h2>

        <p>1.1. Estes Termos de Uso definem as regras a serem seguidas pelo Usuário que acessa e/ou utiliza a Plataforma do Professor Ferretto.</p>

        <h2>2. ACEITAÇÃO</h2>

        <p>2.1. Qualquer pessoa que acessa, utiliza e se cadastra na Plataforma deverá aceitar estes Termos de Uso e todas as demais políticas e princípios da empresa da Plataforma do Professor Ferretto. O Usuário deverá ler, certificar-se de que entendeu estes Termos de Uso e aceitar todas as condições estabelecidas nele antes de se cadastrar na Plataforma.</p>

        <p>2.2. A aceitação destes Termos de Uso é indispensável à utilização da Plataforma. Cada Usuário se responsabiliza integralmente por todos e quaisquer atos que praticar na Plataforma. Caso o Usuário não concorde com quaisquer dos termos e condições abaixo estabelecidos, o Usuário não deve utilizar a Plataforma do Professor Ferretto.</p>

        <p>2.3. Se você, na qualidade de Usuário, não concordar com estes Termos de Uso, você não está autorizado a acessar ou utilizar os Serviços oferecidos pela empresa da Plataforma do Professor Ferretto. A utilização dos Serviços está expressamente condicionada ao consentimento do Usuário às regras dos Termos de Uso.</p>

        <h2>3. SERVIÇOS</h2>

        <p>3.1. A Plataforma do Professor Ferretto oferece os seguintes serviços:</p>

        <p id='p1'>3.1.1. Curso Online Pré-Vestibular. Tem por objetivo a preparação para a prova do Exame Nacional do Ensino Médio (“ENEM”) e vestibulares tradicionais. Sendo ofertadas às seguintes disciplinas: Matemática, Física, Química, Biologia, Filosofia, Sociologia, História, Geografia e Atualidades.</p>

        <p>3.2. Para adquirir o curso Online Pré-Vestibular o Usuário poderá escolher um dos planos de acesso disponíveis na Plataforma do Professor Ferretto, que darão direito a diferentes tempos de acesso, sendo eles:</p>

        <p id='p1'>3.2.1. Plano de acesso anual (12 meses de acesso). O Plano de Acesso Anual dá direito ao Usuário ter acesso total a plataforma;</p>

        <p id='p1'>3.2.2. Plano Mensal (1 mês de acesso). O Plano de Acesso Mensal dá direito ao Usuário ter acesso total a plataforma;</p>

        <p>3.3. O Usuário não poderá adquirir acesso as disciplinas de forma isolada.</p>

        <p>3.4. A empresa da Plataforma do Professor Ferretto orientará a metodologia a ser aplicada no Curso Online Pré-Vestibular, devendo o Usuário respeitar e executar as orientações na forma como a ministrante propõe.</p>

        <p>3.5. A empresa da Plataforma do Professor Ferretto ministra o Curso Online Pré-Vestibular de acordo com cronograma próprio, sendo obrigatório ao Usuário segui-lo, nos termos orientados pela empresa da Plataforma do Professor Ferretto.</p>

        <p>3.6. A empresa da Plataforma do Professor Ferretto não tem responsabilidade por metodologias aplicadas por outras instituições e muito menos em seguir outros padrões, mesmo que análogos, de metodologias diferentes do que consta no cronograma empresa da Plataforma do Professor Ferretto.</p>

        <h2>4. INFORMAÇÕES GERAIS SOBRE A PLATAFORMA DA EMPRESA DA PLATAFORMA DO PROFESSOR FERRETTO</h2>

        <p>4.1. A Plataforma é um ambiente digital que permite ao Usuário adquirir produtos e serviços online com segurança e facilidade, oferecendo experiência de navegação única (“Serviços”).</p>

        <p>4.2. Para acessar a Plataforma de forma segura e utilizar suas funcionalidades de forma integral, o Usuário deverá dispor de dispositivos e equipamentos compatíveis, serviço de conexão à Internet com antivírus e firewall habilitados, softwares devidamente atualizados, além da adoção de medidas de segurança cibernética mínimas, como o uso de senha forte.</p>

        <p>4.3. A Plataforma e suas funcionalidades são apresentadas aos Usuários na maneira como estão disponíveis, podendo passar por constantes aprimoramentos e atualizações, de forma que a empresa da Plataforma do Professor Ferretto se compromete a:</p>

        <p>
          <ol type='I'>
            <li>Preservar o bom funcionamento da Plataforma, com o uso de links funcionais e layout que respeita a usabilidade e navegabilidade, sempre que possível;</li>

            <li>Exibir as funcionalidades de maneira clara, completa, precisa e suficiente de modo que exista a exata percepção das operações realizadas; e</li>

            <li>Garantir, por meio do estado da técnica disponível, o sigilo dos dados, somente acessíveis por pessoas autorizadas pela empresa da Plataforma do Professor Ferretto e por quem o Usuário consentir, além de si próprio.</li>
          </ol>
        </p>
        <h2>5. CADASTRO</h2>

        <p>5.1. Para utilizar os Serviços oferecidos pela empresa da Plataforma do Professor Ferretto é necessário que o Usuário realize um cadastro e aceite as regras expressas nestes Termos de Uso.</p>

        <p>5.2. Ao se cadastrar na Plataforma, o Usuário cria automaticamente uma conta em seu nome (“Conta de Acesso”). No ato do cadastro o Usuário responderá um questionário, no qual serão fornecidas informações pessoais, tais como: nome completo, e-mail, CPF, telefone e endereço.</p>

        <p id='p1'>5.2.1. Após finalizar o cadastro e a compra for autorizada (que pode variar de acordo com a forma de pagamento), o cadastro do Usuário no sistema é efetivado e os dados de acesso são enviados para o Usuário por meio do e-mail.</p>

        <p>5.3. A Conta de Acesso e a senha criada para acesso à Plataforma são únicas, pessoais e intransferíveis. Da mesma forma, nome completo, CPF, e-mail e telefone, informados durante o cadastro somente poderão ser associados a um único Usuário. Em nenhuma hipótese, será aceito pela Plataforma o cadastro de novas contas de titularidade do mesmo Usuário.</p>

        <p id='p1'>5.3.1. O Usuário é responsável pela guarda e sigilo da sua própria senha. Caso venha a esquecer sua senha, uma nova senha poderá ser criada para acesso à Plataforma mediante solicitação informando o endereço de e-mail utilizado no cadastro inicial do Usuário na Plataforma. Uma senha antiga não poderá ser recuperada por solicitação do Usuário.</p>

        <p>5.4. Os Serviços da Plataforma são destinados a pessoas físicas maiores de 18 (dezoito) anos e plenamente capazes no ambiente civil. Caso o Usuário não esteja de acordo com o exigido, não deverá prosseguir com a criação da Conta de Acesso ou utilizar seus serviços sem a presença de um responsável legal, sob pena de ser caracterizada má-fé.</p>

        <p id='p1'>5.4.1. Todos aqueles que não possuírem plena capacidade civil - menores de 18 anos, pródigos, ébrios habituais ou viciados em tóxicos e pessoas que não puderem exprimir sua vontade, por motivo transitório ou permanente - deverão ser devidamente assistidos por seus representantes legais, que se responsabilizarão pelo cumprimento das presentes regras.</p>

        <p>5.5. Ao realizar o cadastro o Usuário:</p>

        <p>
          <ol type='I'>
            <li>deverá oferecer informações verdadeiras sobre si ou da pessoa legalmente autorizada a aceitar este Termo de Uso, sempre que solicitado pela empresa da Plataforma do Professor Ferretto.  Caso as informações solicitadas não sejam enviadas pelo Usuário, a empresa da Plataforma do Professor Ferretto se reserva no direito de cancelar o cadastro a qualquer momento, sem a necessidade de aviso prévio.</li>

            <li>declara que as informações fornecidas no momento do cadastro são corretas, completas e verdadeiras e compromete-se a sempre manter tais informações atualizadas, responsabilizando-se por qualquer prejuízo decorrente da falsidade dessas informações. Em qualquer caso, o Usuário responderá, em âmbito cível e criminal, pela veracidade, exatidão e autenticidade dos dados informados.</li>

            <li>A empresa da Plataforma do Professor Ferretto, em hipótese alguma, será responsável pela veracidade das informações que o Usuário disponibilizar. Todas as informações estão sujeitas às medidas de segurança que impeçam o acesso, o uso e a divulgação não autorizados. Para saber mais sobre a forma que a empresa da Plataforma do Professor Ferretto coleta e processa suas informações, por favor, acesse a nossa Política de Privacidade.</li>
          </ol>
        </p>
        <p>5.6. A empresa da Plataforma do Professor Ferretto se reserva o direito de verificar, a qualquer momento, a veracidade das informações fornecidas pelo Usuário e solicitar, a seu exclusivo critério, esclarecimentos e eventual apresentação de documentação suplementar que julgar necessária para a comprovação das informações prestadas e a validação do cadastro, podendo, inclusive, se recusar a validar qualquer cadastro sem qualquer justificava. Caso o Usuário se recuse a prestar os esclarecimentos ou apresentar os documentos adicionais solicitados pela empresa da Plataforma do Professor Ferretto, sua conta será cancelada e esses Termos de Uso serão automaticamente rescindidos.</p>

        <p>5.7. Caso a empresa da Plataforma do Professor Ferretto detecte alguma Conta de Acesso criada a partir de informações falsas, incompletas, equivocadas, errôneas, enganosas, ou, ainda, não possa detectar a identidade do Usuário, essa conta poderá ser suspensa, a critério da empresa da Plataforma do Professor Ferretto, até que a situação se regularize, de forma que o acesso do Usuário à Plataforma e aos Serviços ficarão bloqueados enquanto durar tal suspensão. A empresa da Plataforma do Professor Ferretto enviará comunicação sobre este bloqueio ao Usuário em até 24 (vinte e quatro) horas após tal medida, através do e-mail cadastrado pelo Usuário.</p>

        <p id='p1'>5.7.1. A EMPRESA DA PLATAFORMA DO PROFESSOR FERRETTO NÃO SERÁ RESPONSÁVEL POR ACESSOS À PLATAFORMA REALIZADOS POR TERCEIROS COM AS INFORMAÇÕES OU SENHA DO USUÁRIO, BEM COMO POR QUALQUER DANO DIRETO OU INDIRETO, RESULTADO DO MAU USO OU DA INABILIDADE DO USO DO PELO USUÁRIO OU POR QUAISQUER TERCEIROS.</p>

        <p>5.8. A empresa da Plataforma do Professor Ferretto deverá ser prontamente contatada em caso de perda, extravio ou suspeita de utilização indevida da Conta de Acesso, para que as medidas adequadas sejam adotadas. Este contato deverá ocorrer através do e-mail: <a href='mailto:contato@professorferretto.com.br'>contato@professorferretto.com.br</a> e <a href='mailto:privacidade@professorferretto.com.br'>privacidade@professorferretto.com.br</a></p>

        <h2>6. CANCELAMENTO E SUSPENSÃO DO CADASTRO</h2>

        <p>6.1. O Usuário tem o direito de solicitar o cancelamento dos serviços na Plataforma em até 7 (sete) dias após a contratação. Neste caso, haverá a devolução integral do valor pago, de acordo com o Código de Defesa do Consumidor (Lei nº 8.078/90).  Toda e qualquer solicitação de cancelamento deverá, necessariamente, ser feita diretamente através do e-mail: <a href='mailto:contato@professorferretto.com.br'>contato@professorferretto.com.br</a>.</p>

        <p id='p1'>6.1.1. O Usuário está ciente que após este período de 7 (sete) dias ele não poderá solicitar o cancelamento e reembolso do Plano contratado.</p>

        <p id='p1'>6.1.2. O Usuário está ciente e concorda que o cancelamento do cadastro implica no cancelamento de seu acesso à Plataforma e da utilização dos Serviços oferecidos.</p>

        <p id='p1'>6.1.3. A empresa da Plataforma do Professor Ferretto não se responsabiliza por qualquer dano ao Usuário oriundo do cancelamento do cadastro.</p>

        <p>6.2. Caso a compra tenha sido realizada com pagamento por cartão de crédito, o reembolso será realizado através de estorno no cartão de crédito no qual a compra foi efetuada. O prazo para efetivação do estorno pela operadora do cartão de crédito pode variar de 30 (trinta) até 60 (sessenta) dias após a solicitação, a depender da data de fechamento da fatura do cartão. Caso a compra tenha sido realizada com pagamento por boleto bancário, o reembolso será realizado através de depósito bancário em conta a ser informada para depósito, em um prazo de até 7 dias úteis. Nenhum pagamento será reembolsável quando solicitado após 7 (sete) dias da data de compra do plano de serviços. E caso a compra tenha sido realizada com pagamento via PIX, a devolução será realizada exclusivamente para a mesma conta pela qual o pagamento foi realizado.</p>

        <p>6.3. A empresa da Plataforma do Professor Ferretto também se reserva o direito de suspender ou cancelar, a qualquer momento, o cadastro do Usuário, bem como seu acesso e uso da Plataforma, em caso de suspeita de fraude, falsidade no conteúdo das informações, obtenção de benefício ou vantagem de forma ilícita, má utilização ou uso inadequado dos serviços ou para fins ilícitos, bem como pelo não cumprimento de quaisquer condições previstas neste Termos de Uso ou na legislação aplicável.</p>

        <p>6.4. Nesses casos, não será devida qualquer indenização ao Usuário, e a empresa da Plataforma do Professor Ferretto poderá promover a competente ação de regresso (ação judicial com o fim de pedir a restituição dos custos assumidos pela empresa da Plataforma do Professor Ferretto), se necessário, bem como tomar quaisquer outras medidas necessárias para perseguir e resguardar seus interesses.</p>

        <p>6.5. A empresa da Plataforma do Professor Ferretto reserva-se o direito de, a seu critério, a qualquer tempo e sem qualquer comunicação prévia ao Usuário, descontinuar de forma definitiva ou temporária os Serviços disponibilizados por meio da Plataforma. Nesses casos, nenhuma indenização será devida ao Usuário.</p>

        <h2>7. CONDICAÇÃO ESPECIAL DE BLACK DAYS: GARANTIA DE CASHBACK PARA CANCELAMENTO E REEMBOLSO DO VALOR PAGO</h2>

        <p>7.1. A garantia de cashback é válida, exclusivamente, para quem adquirir o plano Anual Black entre os dias 01/11/2023 a 30/11/2023, período de promoção da Black Days 2023 do site Professor Ferretto. Para os adquirentes do plano Anual Black, tal como especificado no parágrafo acima, haverá uma garantia adicional de cashback em caso de ingresso em Instituição Pública de Ensino de Nível Superior, exclusivamente pelo Sistema de Seleção Unificada - SISU, 2024/1, com a utilização da nota do ENEM 2023. A garantia de cashback consiste na devolução do valor total pago, referente a compra do plano Anual Black, caso a mesma tenha sido realizada durante o período da promoção de Black Days 2023, que ocorrerá entre os dias 01/11/2023 a 30/11/2023. Essa garantia não se estende para a compra de outros planos de acesso eventualmente disponíveis. Para uso dessa garantia, a matrícula na respectiva Instituição Pública de Ensino de Nível Superior, bem como a solicitação de cancelamento, deverá ser realizada, no máximo, até o dia 30/03/2024. Caso a matrícula do aluno ou a solicitação de cancelamento para uso da garantia venha ser realizada fora desse prazo, o mesmo PERDERÁ a garantia de cashback ofertada.</p>

        <p>Em resumo, para que a garantia de cashback possa ser exigida, os seguintes critérios deverão ser atendidos:</p>

        <p>
          <ol>
            <li>Ter adquirido o plano Diamante Black entre os dias 01/11/2023 a 30/11/2023.</li>

            <li>Comprovar aprovação em alguma Instituição Pública de Ensino de Nível Superior, exclusivamente através do Sistema de Seleção Unificada - SISU, 2024/1, com a utilização da nota do ENEM 2023;</li>

            <li>Apresentar o comprovante de matrícula na respectiva Instituição Pública de Ensino de Nível Superior, sendo a mesma realizada no máximo até a data de 30/03/2024;</li>

            <li>Solicitar o cancelamento e a garantia de cashback, por meio do e-mail de suporte <a href='mailto:contato@professorferretto.com.br'>contato@professorferretto.com.br</a>, no máximo até o dia 30/03/2024.</li>
          </ol>
        </p>
        <p>7.2. Para solicitação da garantia de cashback, o aluno deverá entrar em contato com a equipe de suporte, unicamente através do e-mail <a href='mailto:contato@professorferretto.com.br'>contato@professorferretto.com.br</a>, enviando o comprovante de aprovação pelo Sistema de Seleção Unificada - SISU, 2024/1, e comprovante de matrícula na respectiva Instituição Pública de Ensino de Nível Superior, comprovando que a matrícula tenha sido realizada até a data de 30/03/2024. A solicitação de cancelamento e uso da presente garantia também deverá ser feita, no máximo, até o dia 30/03/2024.</p>

        <p>7.3. É de responsabilidade única e exclusiva do aluno a veracidade das informações prestadas e dados enviados, cabendo a aplicação das penalidades legais, previstas na legislação brasileira, em caso de fraude, falsidade ideológica bem como quaisquer outros crimes digitais. Em caso comprovado de crime digital cometido com o intuito de fraudar a garantia ora oferecida, o aluno PERDERÁ o direito a garantia de cashback ora ofertada.</p>

        <p>7.4. O valor a ser devolvido ao aluno em razão da garantia de cashback compreenderá o valor efetivamente pago na data da compra, sem a incidência de juros ou correção monetária. Caso a compra tenha sido realizada com pagamento por cartão de crédito, o valor será restituído na respectiva fatura no prazo de 30 a 60 dias, a depender da data de fechamento da fatura. Caso a compra tenha sido realizada por meio de boleto bancário ou PIX, a devolução ocorrerá mediante transferência bancária em conta a ser indicada pelo aluno, em prazo de até 7 dias úteis após a formalização do pedido de cancelamento e comprovação dos requisitos elencados na cláusula 7.1.</p>

        <p>7.5. Em caso de cancelamento da compra e uso da garantia de cashback, o aluno PERDERÁ, de imediato, o acesso a plataforma do Professor Ferretto.</p>

        <h2>8. PROPRIEDADE INTELECTUAL</h2>

        <p>8.1. Todos os direitos de propriedade intelectual relativos à Plataforma, bem como todas as suas funcionalidades, são de propriedade exclusiva da empresa da Plataforma do Professor Ferretto, inclusive no que diz respeito aos seus textos, imagens, layouts, software, códigos, bases de dados, gráficos, artigos, fotografias e demais conteúdos análogos produzidos direta ou indiretamente pela empresa da Plataforma do Professor Ferretto l (“Conteúdo empresa da Plataforma do Professor Ferretto”).</p>

        <p id='p1'>8.1.1. Inclui-se nesse dispositivo o uso da marca “Professor Ferretto”, nome empresarial ou nome de domínio, além das telas da Plataforma, assim como os programas, banco de dados, redes, arquivos que permitem que o Usuário acesse sua conta são de propriedade da empresa da Plataforma do Professor Ferretto e estão protegidos pelas leis e tratados internacionais de direito autoral e propriedade industrial.</p>

        <p id='p1'>8.1.2. O Conteúdo empresa da Plataforma do Professor Ferretto é protegido pelas leis de direitos autorais e de propriedade industrial. É proibido usar, copiar, reproduzir, modificar, traduzir, publicar, transmitir, distribuir, executar, fazer o upload, exibir, licenciar, vender ou explorar e fazer engenharia reversa do Conteúdo empresa da Plataforma do Professor Ferretto, para qualquer finalidade, sem o consentimento prévio e expresso da empresa da Plataforma do Professor Ferretto. Qualquer uso não autorizado do Conteúdo empresa da Plataforma do Professor Ferretto será considerado como violação dos direitos autorais e de propriedade industrial da empresa da Plataforma do Professor Ferretto.</p>

        <p>8.2. Todos os feedbacks, opiniões, sugestões de melhoria ou outras ideias fornecidas pelo Usuário à Plataforma, não conferirão qualquer titularidade sobre os direitos de propriedade intelectual ao Usuário, mesmo que as sugestões sejam eventualmente implementadas. Assim, todas as melhorias, opiniões, sugestões, ideias, comentário, feedbacks são desde já cedidos pelo Usuário à empresa da Plataforma do Professor Ferretto, de forma gratuita, irrevogável, irretratável, total, perpétua, sem que seja devido ao Usuário qualquer remuneração, reconhecimento, pagamento ou indenização.</p>

        <p>8.3. A empresa da Plataforma do Professor Ferretto reserva-se o direito de, a seu critério e a qualquer tempo, alterar ou remover funcionalidades da Plataforma que não estejam alinhadas com seus interesses, bem como adicionar novas funcionalidades que tragam benefícios à utilização da Plataforma pelo Usuário, sem qualquer comunicação prévia e sem que seja devido ao Usuário qualquer indenização.</p>

        <p>8.4. A empresa da Plataforma do Professor Ferretto poderá, a seu exclusivo critério, durante a vigência destes Termos de Uso, atualizar a Plataforma com o objetivo de preservar ou aperfeiçoar suas características funcionais.</p>

        <p id='p1'>8.4.1. Nesse caso, suspensões temporárias nos Serviços poderão acontecer, sem que seja devida qualquer indenização ao Usuário. Todo e qualquer desenvolvimento, arranjo, melhoria ou alteração da Plataforma ou de suas funcionalidades, realizados pela empresa da Plataforma do Professor Ferretto, ainda que por sugestão ou solicitação do Usuário, serão considerados de titularidade da empresa da Plataforma do Professor Ferretto de forma que esta poderá empregá-los livremente e adaptá-los, aperfeiçoá-los, transformá-los, distribuí-los, comercializá-los, levá-los a registro, cedê-los e licenciá-los, a qualquer título e a seu exclusivo critério, sem limitação.</p>

        <p>8.5. É vedado ao Usuário incluir na Plataforma dados que possam modificar o seu conteúdo ou sua aparência.</p>

        <p>8.6. Os Instrutores autorizaram a empresa da Plataforma do Professor Ferretto a utilizar sua imagem, nome e voz nos Cursos, Material Auxiliar e Conteúdo, exclusivamente para sua disponibilização na Plataforma e demais redes sociais, blogs e domínios de titularidade da empresa da Plataforma do Professor Ferretto e/ou de seus parceiros comerciais. Desse modo, qualquer reprodução, transmissão, veiculação, comercialização e exibição indevida dos Cursos, Materiais Auxiliares e Conteúdos disponibilizados na Plataforma e demais redes sociais, blogs e domínios de titularidade da empresa da Plataforma do Professor Ferretto e/ou de seus parceiros comerciais, constitui, além da violação de direitos de propriedade intelectual, violação dos direitos de imagem, nome e voz do Instrutores, sendo de responsabilidade exclusiva do Usuário indenizar o Expert em razão da infração às disposições dos presentes Termos, sem prejuízo da reparação dos danos e de todas as despesas incorridas pela empresa da Plataforma do Professor Ferretto em função da infração</p>

        <h2>9. RESPONSABILIDADES DA EMPRESA DA PLATAFORMA DO PROFESSOR FERRETTO</h2>

        <p>9.1. Tendo em vista as características inerentes ao ambiente da internet, o Usuário reconhece que a empresa da Plataforma do Professor Ferretto não se responsabiliza pelas falhas na Plataforma decorrentes de circunstâncias alheias à sua vontade e controle, sejam ou não ocasionadas por caso fortuito ou força maior, como por exemplo, intervenções de hackers e softwares maliciosos; falhas técnicas de qualquer tipo, incluindo, falhas no acesso ou na navegação na Plataforma decorrentes de falhas na internet em geral, quedas de energia, mau funcionamento eletrônico e/ou físico de qualquer rede, interrupções ou suspensões de conexão e falhas de software e/ou hardware do Usuário; paralisações programadas para manutenção, atualização e ajustes de configuração da Plataforma,  qualquer falha humana de qualquer outro tipo, que possa ocorrer durante o processamento das informações. O Usuário exime a empresa da Plataforma do Professor Ferretto de qualquer responsabilidade proveniente de tais fatos e/ou atos.</p>

        <p>9.2. A empresa da Plataforma do Professor Ferretto oferecerá suporte básico ao Usuário com relação aos Serviços, o que implica no esclarecimento de dúvidas com relação ao uso da Plataforma por meio da Central de Atendimento, de segunda à sexta, das 08h às 12h e das 13h ás 18h, através do e-mail <a href='mailto:contato@professorferretto.com.br'>contato@professorferretto.com.br</a> ou pelo chat online na Plataforma.</p>

        <p>9.3. A empresa da Plataforma do Professor Ferretto reserva-se o direito de modificar, suspender ou descontinuar temporariamente as funcionalidades disponibilizadas na Plataforma para realizar a manutenção, atualização e ajustes de configuração.</p>

        <p>9.4. A empresa da Plataforma do Professor Ferretto não se responsabiliza pelo recebimento de e-mails falsos e mensagens, enviados por terceiros sem qualquer relação direta ou indireta com a empresa da Plataforma do Professor Ferretto e/ou empresas do grupo.</p>

        <h2>10. RESPONSABILIDADE DO USUÁRIO</h2>

        <p>10.1. O Usuário fica ciente e concorda que, na utilização Plataforma, é terminantemente proibido:</p>

        <p>
          <ol type='I'>
            <li>distribuir, modificar, vender, alugar ou de qualquer forma explorar economicamente a Plataforma, os dados e informações a ela relacionados, bem como utilizá-los para finalidade que não seja para uso próprio;</li>

            <li>fornecer à empresa da Plataforma do Professor Ferretto, por ocasião do cadastro na Plataforma, informações falsas, inexatas, desatualizadas ou incompletas, bem como assumir intencionalmente a personalidade de outra pessoa, física ou jurídica;</li>

            <li>disseminar ou instalar vírus ou qualquer outro código, arquivo ou software com o propósito de interromper, destruir, acessar indevidamente, limitar ou interferir no funcionamento ou segurança do serviço oferecido pela empresa da Plataforma do Professor Ferretto, bem como nas informações, dados e equipamentos da empresa da Plataforma do Professor Ferretto, de seus usuários ou de terceiros, ou, ainda, para qualquer outra finalidade ilícita; e</li>

            <li>praticar qualquer ato contrário à legislação em vigor.</li>
          </ol>
        </p>
        <p>10.2. Independente da realização do cadastro, o Usuário é exclusivamente responsável pelo uso da Plataforma e deverá respeitar as regras destes Termos de Uso, bem como a legislação aplicável ao uso da Plataforma.</p>

        <p>10.3. São responsabilidades do Usuário:</p>

        <p>
          <ol type='I'>
            <li>Utilizar a Plataforma de forma adequada e diligente, em conformidade com os presentes Termos de Uso, com a lei, a moral, os bons costumes e a ordem pública;</li>

            <li>Manter seguro o ambiente de seus dispositivos de acesso à Plataforma, valendo-se de ferramentas específicas para tanto, tais como antivírus, firewall, entre outras, de modo a contribuir para a prevenção de riscos eletrônicos;</li>

            <li>Utilizar navegadores e sistemas operacionais atualizados e eficientes para a plena utilização da Plataforma; e</li>

            <li>Equipar-se e responsabilizar-se pelos dispositivos de hardware necessários para o acesso à Plataforma, bem como pelo acesso desses à internet; e</li>

            <li>É de total responsabilidade do Usuário manter em sigilo suas senhas, sendo elas pessoais e intransferíveis. Dessa maneira, a empresa da Plataforma do Professor Ferretto não será, em nenhum cenário, responsável por quaisquer prejuízos causados ao Usuário ou a terceiros pela divulgação e utilização indevida e não autorizada das senhas.</li>
          </ol>
        </p>
        <p>10.4. Qualquer dano causado pelo Usuário à empresa da Plataforma do Professor Ferretto ou a terceiros em virtude do não cumprimento das obrigações aqui dispostas ou da não veracidade das garantias aqui declaradas serão reparados exclusivamente pelo Usuário causador do dano, não havendo que se falar em subsidiariedade da obrigação, tampouco em solidariedade da empresa da Plataforma do Professor Ferretto.</p>

        <p>10.5. No caso de infração às regras acima, o Usuário poderá ser suspenso ou banido, sem prejuízo da responsabilidade legal quando aplicável, respondendo inteiramente por sua conduta.</p>

        <h2>11. SERVIÇO DE ATENDIMENTO AO USUÁRIO</h2>

        <p>11.1. O Usuário poderá entrar em contato direto com a empresa da Plataforma do Professor Ferretto através do nosso e-mail: <a href='mailto:contato@professorferretto.com.br'>contato@professorferretto.com.br</a> ou pelo chat online.</p>

        <p>11.2. Todas as comunicações realizadas pela empresa da Plataforma do Professor Ferretto com o Usuário serão feitas pelo endereço de e-mail informado pelo Usuário no momento do cadastro ou, ainda, serão realizadas através dos canais de comunicação disponibilizados pela empresa da Plataforma do Professor Ferretto na própria Plataforma.</p>

        <p id='p1'>11.2.1. O Usuário obriga-se a informar imediatamente quaisquer mudanças de seu endereço de e-mail, a fim de que possa receber regularmente comunicações importantes enviadas pela empresa da Plataforma do Professor Ferretto.</p>

        <p id='p1'>11.2.2. É dever do usuário deixar os sistemas AntiSpam de seu e-mail configurados de modo que não interfiram no recebimento dos comunicados enviados pela empresa da Plataforma do Professor Ferretto. A responsabilidade pelo recebimento e visualização dos comunicados é exclusiva do Usuário.</p>

        <h2>12. DOS LINKS EXTERNOS</h2>

        <p>12.1. A Plataforma pode conter links para portais e aplicativos de terceiros que não pertencem e não são controlados pela empresa da Plataforma do Professor Ferretto. Apesar das verificações prévias e regulares realizadas pela empresa da Plataforma do Professor Ferretto, esta não endossa ou garante ou possui qualquer ligação com os proprietários desses portais ou aplicativos detidos por terceiros, não sendo responsável pelo seu conteúdo, precisão, políticas, práticas ou opiniões expressas em qualquer desses portais e aplicativos de terceiros com os quais o Usuário interaja através da Plataforma. A empresa da Plataforma do Professor Ferretto recomenda que o Usuário leia os Termos de Uso e as Políticas de Privacidade de cada site e aplicativo de terceiros ou serviço que o Usuário vier a visitar ou utilizar, pois a empresa da Plataforma do Professor Ferretto se isenta de qualquer responsabilidade sobre o conteúdo encontrado nestes sites e serviços.</p>

        <p>12.2. Não será autorizada a inclusão de páginas que divulguem quaisquer tipos de informações ilícitas, violentas, polêmicas, pornográficas, xenofóbicas, discriminatórias ou ofensivas.</p>

        <p>12.3. A Plataforma se reserva o direito de retirar a qualquer momento um link levando ao seu próprio serviço, se a página de origem não estiver conforme os seus termos e políticas.</p>

        <h2>13. ALTERAÇÃO DESTES TERMOS DE USO</h2>

        <p>13.1. A empresa da Plataforma do Professor Ferretto está sempre fazendo atualizações na Plataforma para melhorar a prestação dos Serviços. Por esse motivo, estes Termos de Uso podem ser alterados, a qualquer tempo, a fim de refletir os ajustes realizados. Sempre que ocorrer qualquer modificação nestes Termos de Uso, o Usuário será previamente informado e deverá concordar com eles para continuar acessando a Plataforma.</p>

        <p id='p1'>13.1.1. Caso o Usuário não concorde com os novos Termos de Uso, ele poderá rejeitá-los, mas isso significa que o uso da Plataforma não será mais permitido. Se de qualquer maneira o Usuário utilizar a Plataforma mesmo após a alteração destes Termos de Uso, isso significa que o Usuário concorda com todas as modificações.</p>

        <h2>14. OUTRAS DISPOSIÇÕES</h2>

        <p>14.1. A empresa da Plataforma do Professor Ferretto poderá, a qualquer momento, ceder quaisquer de seus direitos e obrigações previstos nestes Termos de Uso a qualquer pessoa, física ou jurídica, mediante simples notificação prévia ao Usuário, ficando desde já ressalvado que a cessionária continuará a cumprir com todas as obrigações assumidas pela empresa da Plataforma do Professor Ferretto, conforme o caso.</p>

        <p>14.2 Estes Termos de Uso não criam qualquer outra modalidade de vínculo entre o Usuário e a empresa da Plataforma do Professor Ferretto, inclusive, sem limitação, sociedade, mandato, parceria, associação, joint-venture, consórcio, grupo econômico, vínculo empregatício ou similar. A empresa da Plataforma do Professor Ferretto permanecerá uma entidade independente e autônoma.</p>

        <h2>15. DAS DISPUTAS</h2>

        <p>15.1. Esses Termos de Uso são regidos pelas leis da República Federativa do Brasil.</p>

        <p>15.2. Todas as controvérsias deste Termos de Uso serão solucionadas pelo foro da Comarca de Passo Fundo, Estado do Rio Grande do Sul, Brasil, com exclusão de qualquer outro, por mais privilegiado que seja ou venha a ser.</p>

        <p>DADOS DE CONTATO

          <br />FERRETTO CURSOS ON LINE LTDA

          <br />CNPJ nº 40.424.646/0001-10

          <br />Rua Uruguai, nº 421, Sala 802

          <br />Centro, Passo Fundo/RS

          <br />CEP 99.010-110

          <br /><a href='mailto:privacidade@professorferretto.com.br'>privacidade@professorferretto.com.br</a>

          <br /><a href='mailto:contato@professorferretto.com.br'>contato@professorferretto.com.br</a>

          <br />Redes Sociais:

          <br /><a href='https://facebook.com/professorferretto'>Facebook</a>

          <br /><a href='https://www.instagram.com/professorferretto'>Instagram</a>

          <br />Chat online do Site

          <br />Horários de Atendimento

          <br />Segunda à Sexta. Das 08h às 12h e das 13h às 18h (menos feriados nacionais, estaduais e municipais.
        </p>
      </Main>
      <LeadCapture border />
      <Footer />
    </Layout>
  )
}

const Link = styled(props => <GatsbyLink {...props} />)`
  color: ${({ theme }) => theme.colors.third};
  text-decoration: none;
  border-bottom: 1px solid currentColor;
`

const Main = styled.main`
  margin: 0 auto;
  max-width: 70rem;
  padding: 5rem 2rem;

  p {
    font-size: 1.4rem;
    line-height: 1.6;
    padding-left: 2rem;
    margin-bottom: 2rem;
    text-align: justify;

    a {
      color: ${({ theme }) => theme.colors.third};
      text-decoration: none;
      border-bottom: 1px solid currentColor;
    }
  }

  #p1 {
    padding-left: 100px;
  }

  h1, h2, h3 {
    color: ${({ theme }) => theme.colors.third};
  }

  h1 {
    font-size: 2.4rem;
    line-height: 1.6;
    text-transform: uppercase;
    padding: 4rem 0 2rem;
  }

  h2 {
    font-size: 1.8rem;
    line-height: 1.6;
    padding: 2rem 0;
  }

  h3 {
    font-size: 1.4rem;
    line-height: 1.6;
    padding: 2rem 0 1rem;
  }
`
